// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAp9UwOdQ_LL4z8MyRx_6EANN7VERAhQms",
  authDomain: "crypto-342815.firebaseapp.com",
  databaseURL: "https://crypto-342815-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "crypto-342815",
  storageBucket: "crypto-342815.appspot.com",
  messagingSenderId: "223617023494",
  appId: "1:223617023494:web:013ffc5d44ee50c5900d87",
  measurementId: "G-8JXFYZ5D8V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app)
export {db}
/*
* 
*/