
import {
    Horizontal
 } from '../../modules/dashboard/Horizontal'
                 
export function SelectRisk() {
   return  <div>
               <div className='row g-5 g-xl-12'>
                   <Horizontal />
               </div>
           </div>
} 