import dotenv from  'dotenv'

const MasterConfix = {
    "LINE_NOTI_URL": (process.env.REACT_APP_LINE_NOTI_URL as string)
    ,"LINE_NOTI_MSG": (process.env.REACT_APP_LINE_NOTI_MSG as string)
    ,"API_BASE_ENDPOINT": (process.env.REACT_APP_API_BASE_ENDPOINT as string)
    ,"API_SAVE_SECRET_ENDPOINT": (process.env.REACT_APP_API_SAVE_SECRET_ENDPOINT as string)
    ,"API_VALIDATE_APIKEY": (process.env.REACT_APP_API_VALIDATE_APIKEY as string)
    ,"AUTH0_CLIENT_ID": (process.env.REACT_APP_AUTH0_CLIENT_ID as string)
    ,"AUTH0_DOMAIN": (process.env.REACT_APP_AUTH0_DOMAIN as string)
    ,"API_GET_STRATEGY_DETAIL": (process.env.REACT_APP_API_GET_STRATEGY_DETAIL as string)
    ,"API_GRID_CREATE_ZONE": (process.env.REACT_APP_API_GRID_CREATE_ZONE as string)
    ,"API_GET_ACCOUNT_BALANCE": (process.env.REACT_APP_API_GET_ACCOUNT_BALANCE as string)
    ,"API_GET_STRATEGY_BY_RISK": (process.env.REACT_APP_API_GET_STRATEGY_BY_RISK as string)
    ,"API_VERIFY_AUTHY": (process.env.REACT_APP_API_API_VERIFY_AUTHY as string)
    ,"API_DELETE_SECRET_ENDPOINT": (process.env.REACT_APP_API_DELETE_SECRET_ENDPOINT as string)
    
}   

export default MasterConfix