/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth0} from "@auth0/auth0-react";
import {useHistory} from 'react-router-dom'
import {collection, query, getDocs, where } from 'firebase/firestore'
import {db} from '../../../../firebase'
import {MyStrategyChart} from "./MyStrategyChart"

type Props = {
  className: string
}

const DashboardDetail_MyBots: React.FC<Props> = ({className}) => {

  const { user } = useAuth0();
  const userId = user!.sub!.replaceAll('|','')
  const [data, setData] = useState([] as any[])
  const [loading, setLoading] = useState(true)

  const getMyStrategy = async()=> {
    
    const rootColRef = query(collection(db, 'users'), where("user_id", "==", userId ))
    let my_strategys = [] as any[]
    const data = await getDocs(rootColRef).then( async(querySnapshot) => {
        const allDocResult = await Promise.all(querySnapshot.docs.map( async(docData:any) => {
            let data_userId = docData.id
            const refAccountCol = query(collection(db, 'users', docData.id, 'accounts'))
            const accountData = await getDocs(refAccountCol).then( async(accountSnapshot) => {
                const accountResult = await Promise.all(accountSnapshot.docs.map( async(account:any) => {
                    const refStrategyCol = collection(db, 'users', data_userId, 'accounts', account.id, 'strategys')
                    const strategyData = await getDocs(refStrategyCol).then( async(strategySnapshot) => {
                        const strategyResult = await Promise.all(strategySnapshot.docs.map( async(strategy:any) => {
                            let data_strategy_id = strategy.id
                            my_strategys.push({id: strategy.id, data: strategy.data()})
                            return data_strategy_id 
                        }))   
                        
                       return strategyResult 
                    })
                    return strategyData
                }))
                
                return accountResult
            })
            return accountData
        }))
        return allDocResult
    })
    return my_strategys
  }

  const getStrategyData = async() => {
    const myStrategys = await getMyStrategy()
    if (myStrategys!=undefined && myStrategys!=null && myStrategys.length>0){
      //let myStrategysArray = myStrategys.split(",")
      //const strategysDetail = await getStrategyDetail(myStrategys)
      setData(myStrategys)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  useEffect( () => {
    //function for get my strategy detail
    const getMyStrategyDetail = async()=>{
      await getStrategyData()
    }

    //call function
    getMyStrategyDetail()

    //clear memory leak
    return () => {
      setData([]); // This worked for me
    };
    
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>My Strategies</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {/* begin::Item */}
        { data.map( el=> el != undefined ? (
          <div className='d-flex mb-6' key={el!.id}>
            {/* begin::Avatar */}
            {/* <div className='symbol symbol-50px me-5'>
                <MyStrategyChart
                  className='card-xl-stretch mb-xl-8'
                  chartColor='danger'
                  chartHeight='50%'
                  chartWidth='30%'
                />
            </div> */}
            {/* end::Avatar */}
            {/* begin::Text */}
            <div className='flex-grow-1 text-left'>
              <a href='#' className='text-dark fw-bolder text-hover-primary fs-4'>
                {el!.data!.strategy_type}
              </a>
              <span className='text-muted d-block fw-bold'>Strategy Type</span>
            </div>
            {/* end::Text */}
            {/* begin::Text */}
            <div className='flex-grow-1 text-left'>
              <a href='#' className='text-dark fw-bolder text-hover-primary fs-4'>
              {el!.data!.asset}
              </a>
              <span className='text-muted d-block fw-bold'>Asset</span>
            </div>
            {/* end::Text */}
            {/* begin::Text */}
            <div className='flex-grow-1 text-left'>
              <a href='#' className='text-dark fw-bolder text-hover-primary fs-4'>
              {el!.data!.system_status}
              </a>
              <span className='text-muted d-block fw-bold'>Status</span>
            </div>
            {/* end::Text */}
          </div>
        ):(
          <></>
        ))}
        {/* end::Item */}
        
      </div>
      {/* end::Body */}
    </div>
  )
}

export {DashboardDetail_MyBots}
