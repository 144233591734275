
import {
    ExchangeList
  } from "../../modules/setup/ExchangeList"
                  
export function ExchangeSetup() {

    return  (<>
                <ExchangeList className='mb-5 mb-xl-8' />
            </>)
}